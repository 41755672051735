import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Facts = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomeFacts5 {
        title
        text
        facts {
          id
          title
          text
          description {
            description
          }
        }
        highlightedTitle
        highlightedText {
          highlightedText
        }
      }
    }
  `)
  const {
    title,
    text,
    facts,
    highlightedTitle,
    highlightedText,
  } = data.contentfulHomeFacts5

  return (
    <div className="the-facts-section">
      <div className="container">
        <div className="the-facts-block">
          <div className="the-facts-label">
            <p>{text}</p>
          </div>
          <div className="section_title">
            <h2>{title}</h2>
          </div>
          <div className="the-facts-inner-block">
            {facts?.map(fact => (
              <div className="fact-col" key={fact.id}>
                <div className="the-facts-box">
                  <div className="fact-box-title">
                    <h3>{fact.title}</h3>
                  </div>
                  <div className="facts-sub-label">
                    <p>{fact.text}</p>
                  </div>
                  <div className="common-desc">
                    <p>{fact.description.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="the-red-bg-box">
            <div className="red-bg-large-text">
              <h3>{highlightedTitle}</h3>
            </div>
            <div className="red-bg-desc">
              <p>{highlightedText.highlightedText}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Facts
