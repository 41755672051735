import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HealthGuides = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomeHealthGuides9 {
        title
        text
        description
        guide {
          id
          image {
            gatsbyImageData
          }
          title
          description
          linkLabel
          linkUrl
        }
      }
    }
  `)
  const { title, text, description, guide } = data.contentfulHomeHealthGuides9

  return (
    <div className="health-guides-section">
      <div className="container">
        <div className="health-guides-block">
          <div className="health-content">
            <p className="review-label">{text}</p>
            <div className="section_title">
              <h2>{title}</h2>
            </div>
            <div className="common-desc">
              <p>{description}</p>
            </div>
          </div>
          <div className="health-guides-inner-block">
            {guide.map((el, i) => (
              <div className="hg-col" key={i}>
                <div className="health-box">
                  <div className="hg-image">
                    <GatsbyImage
                      image={getImage(el.image)}
                      alt="health image"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="hg-content-block">
                    <div className="hg-box-title">
                      <h3>
                        <Link to={el.linkUrl} target="_blank">{el.title}</Link>
                      </h3>
                    </div>
                    <div className="common-desc">
                      <p>{el.description}</p>
                    </div>
                    <div className="common-section-btn">
                      <Link to={el.linkUrl} className="common-btn" target="_blank">
                        {el.linkLabel}
                        {el.linkLabel === "Get Notified" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.586"
                            height="14.927"
                            viewBox="0 0 13.586 14.927"
                          >
                            <g
                              data-name="Icon feather-bell"
                              transform="translate(-3.75 -2.25)"
                            >
                              <path
                                data-name="Path 83"
                                d="M14.572,7.029a4.029,4.029,0,1,0-8.057,0c0,4.7-2.014,6.043-2.014,6.043H16.586s-2.014-1.343-2.014-6.043"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                data-name="Path 84"
                                d="M17.728,31.5a1.343,1.343,0,0,1-2.323,0"
                                transform="translate(-6.024 -15.742)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        )}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div id="faq"></div>
    </div>
  )
}

export default HealthGuides
