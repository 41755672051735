import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const WeBelieve = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomeWhatWeBelieve2 {
        title
        text
        believe {
          id
          title
          image {
            file {
              url
            }
          }
        }
      }
    }
  `)
  const { title, text, believe } = data.contentfulHomeWhatWeBelieve2

  return (
    <div className="what-we-believe-section">
      <div className="container">
        <div className="what-we-believe-block">
          <div className="common-section-label">
            <p>{text}</p>
          </div>
          <div className="common-section-light-title">
            <h2>{title}</h2>
          </div>

          <div className="what-we-believe-inner-block">
            {believe?.map(el => (
              <div className="wwb-col" key={el.id}>
                <div className="wwb-box">
                  <div className="wwb-icon">
                    <img src={el.image.file.url} alt="wwb icon" />
                  </div>
                  <div className="wwb-title">
                    <h4>{el.title}</h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeBelieve
