import React from "react"
import Layout from "../components/layout"
import Banner from "../components/homepage/banner"
import WeBelieve from "../components/homepage/we_believe"
import WhoWeAre from "../components/homepage/who_we_are"
import WeCanHelp from "../components/homepage/we_can_help"
import Facts from "../components/homepage/facts"
import HealthPlatform from "../components/homepage/health_platform"
import HowBrigoWorks from "../components/homepage/how_brigo_works"
// import Review from "../components/homepage/review" hiding
import HealthGuides from "../components/homepage/health_guides"
import FAQs from "../components/homepage/faqs"
import GetStarted from "../components/homepage/get_started"
import OurPromise from "../components/product/our_promise"

const IndexPage = () => {
  return (
    <Layout>
      <div className="main-content">
        <Banner />
        <WeBelieve />
        <WhoWeAre />
        <WeCanHelp />
        <Facts />
        <HealthPlatform />
        <HowBrigoWorks />
        <OurPromise />
        {/* <Review /> */}
        <HealthGuides />
        <FAQs />
        <GetStarted />
      </div>
    </Layout>
  )
}

export default IndexPage
