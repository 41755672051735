import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const WhoWeAre = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomeWhoWeAre3 {
        title
        rich {
          raw
        }
      }
    }
  `)
  const { title, rich } = data.contentfulHomeWhoWeAre3
  return (
    <div className="who-we-are-section">
      <div className="container">
        <div className="who-we-are-block">
          <div className="who-we-are-title">
            <h3>{title}</h3>
          </div>
          <div className="who-we-desc">
            <h4>

            {renderRichText(rich)}
            </h4>

          </div>
        </div>
      </div>
    </div>
  )
}

export default WhoWeAre

