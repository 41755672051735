import React, { useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import CircleType from "circletype";
import RightArrow from "../../images/arrow-black.svg";

const HowBrigoWorks = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomeHowBrigoWorks7 {
        text
        heading
        image1a {
          gatsbyImageData
        }
        title1
        text1
        image2a {
          gatsbyImageData
        }
        title2
        text2
        image3a {
          gatsbyImageData
        }
        title3
        text3
      }
    }
  `);
  const {
    text,
    heading,
    image1a,
    title1,
    text1,
    image2a,
    title2,
    text2,
    image3a,
    title3,
    text3,
  } = data.contentfulHomeHowBrigoWorks7;

  const circleInstanceTwo = useRef();
  const circleInstanceTwoMobile = useRef();
  const circleInstanceThree = useRef();
  const circleInstanceThreeMobile = useRef();
  const circleInstanceNew = useRef();
  const circleInstanceNewMobile = useRef();

  useEffect(() => {
    new CircleType(circleInstanceNew.current)
      .radius(200)
      .dir(-1)
      .forceWidth(250);
    new CircleType(circleInstanceTwo.current)
      .radius(200)
      .dir(-1)
      .forceWidth(250);
    new CircleType(circleInstanceTwoMobile.current)
      .radius(200)
      .dir(-1)
      .forceWidth(250);
    new CircleType(circleInstanceThree.current)
      .radius(200)
      .dir(-1)
      .forceWidth(250);
    new CircleType(circleInstanceThreeMobile.current)
      .radius(200)
      .dir(-1)
      .forceWidth(250);
    new CircleType(circleInstanceNewMobile.current)
      .radius(200)
      .dir(-1)
      .forceWidth(250);
  }, []);
  return (
    <div className="how-brigo-works-section">
      <div className="container">
        <div className="how-brigo-works-block">
          <div className="common-section-label">
            <p>{text}</p>
          </div>
          <div className="common-section-light-title">
            <h2>{heading}</h2>
          </div>
          <div className="mobile-how-work-title section_title">
            <h2>How Brigo Works</h2>
          </div>
          <div className="how-brigo-works-inner-block">
            <div className="common-timeline-block timeline-one">
              <div className="timeline-col">
                <div className="timeline-image-block">
                  <div className="timeline-img">
                    <GatsbyImage
                      image={getImage(image1a)}
                      alt="timeline img"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div
                    ref={circleInstanceNew}
                    className="circularText itemTwo itemNew desktopOnly"
                  >
                    GOODBYE AWKWARD CONVERSATIONS
                  </div>
                  <div
                    ref={circleInstanceNewMobile}
                    className="circularText itemTwo itemNewNew mobileOnly"
                  >
                    GOODBYE AWKWARD CONVERSATIONS
                  </div>
                  <div className="timeline-line-draw">
                    <span></span>
                  </div>
                </div>
              </div>
              <div className="timeline-col">
                <div className="timeline-content-block">
                  <p className="timeline-number">01</p>
                  <div className="timeline-title">
                    <h3>{title1}</h3>
                  </div>
                  <div className="common-desc">
                    <p>{text1} </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="common-timeline-block timeline-two">
              <div className="timeline-col">
                <div className="timeline-content-block">
                  <p className="timeline-number">02</p>
                  <div className="timeline-title">
                    <h3>{title2}</h3>
                  </div>
                  <div className="common-desc">
                    <p>{text2}</p>
                  </div>
                </div>
              </div>
              <div className="timeline-col">
                <div className="timeline-image-block">
                  <div className="timeline-img">
                    <GatsbyImage
                      image={getImage(image2a)}
                      alt="timeline img"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div
                    ref={circleInstanceTwo}
                    className="circularText itemTwo desktopOnly"
                  >
                    GOODBYE WAITING ROOMS
                  </div>
                  <div
                    ref={circleInstanceTwoMobile}
                    className="circularText itemTwo mobileOnly"
                  >
                    GOODBYE WAITING ROOMS
                  </div>
                  <div className="timeline-line-draw">
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="common-timeline-block timeline-three">
              <div className="timeline-col">
                <div className="timeline-image-block">
                  <div className="timeline-img">
                    <GatsbyImage
                      image={getImage(image3a)}
                      alt="timeline img"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div
                    ref={circleInstanceThree}
                    className="circularText itemThree desktopOnly"
                  >
                    GOODBYE PHARMACY LINES
                  </div>
                  <div
                    ref={circleInstanceThreeMobile}
                    className="circularText itemThree mobileOnly"
                  >
                    GOODBYE PHARMACY LINES
                  </div>
                </div>
              </div>
              <div className="timeline-col">
                <div className="timeline-content-block">
                  <p className="timeline-number">03</p>
                  <div className="timeline-title">
                    <h3>{title3}</h3>
                  </div>
                  <div className="common-desc">
                    <p>{text3} </p>
                  </div>
                </div>
              </div>
              <a className="mobileOnly common-btn ">
                Start a Visit <img src={RightArrow} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowBrigoWorks;
