import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

const GetStarted = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulGetStarted {
        heading
        subheading
        linkLabel
        linkUrl
      }
    }
  `)
  const { heading, subheading, linkLabel, linkUrl } = data.contentfulGetStarted
  return (
    <div className="ready-to-get-section">
      <div className="container">
        <div className="ready-to-get-block">
          <div className="ready-to-content">
            <h2>{heading}</h2>
            <h3>{subheading}</h3>
          </div>
          <div className="common-section-btn">
            <Link to={linkUrl} className="common-btn">
              {linkLabel}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetStarted
