import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const WeCanHelp = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomeWeCanHelp4 {
        title
        text
        help {
          id
          title
          image {
            gatsbyImageData(quality: 100)
          }
          description {
            description
          }
          linkLabel
          linkUrl
        }
      }
    }
  `)
  const { title, text, help } = data.contentfulHomeWeCanHelp4
  return (
    <div className="we-dont-just-section" id="weCanHelp">
      <div className="container">
        <div className="we-dont-just-block">
          <div className="common-section-label">
            <p>{text}</p>
          </div>
          <div className="we-dont-just-title">
            <h2>{title}</h2>
          </div>
          <div className="we-dont-just-inner-block">
            {help?.map((el, i) => {
              if (i % 2 === 0) {
                return (
                  <div className="we-just-common-block" key={el.id}>
                    <div className="we-just-col">
                      <div className="we-just-content-block">
                        <div className="we-just-title">
                          <h3>{el.title}</h3>
                        </div>
                        <div className="common-desc">
                          <p>{el.description.description}</p>
                        </div>
                        <div className="common-section-btn">
                          <Link to={el.linkUrl} className="common-btn">
                            {el.linkLabel}
                          </Link>
                          <Link to='/erectile-dysfunction'>
                        Learn about ED</Link>
                        </div>
                      </div>
                    </div>
                    <div className="we-just-col we-order">
                      <div className="we-just-image-block">
                        <GatsbyImage
                          image={getImage(el.image)}
                          alt="We Just Img"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div className="we-just-common-block rtl-block" key={i}>
                    <div className="we-just-col">
                      <div className="we-just-image-block">
                        <GatsbyImage
                          image={getImage(el.image)}
                          alt="We Just Img"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="we-just-col">
                      <div className="we-just-content-block">
                        <div className="we-just-title">
                          <h3>{el.title}</h3>
                        </div>
                        <div className="common-desc">
                          <p>{el.description.description}</p>
                        </div>
                        <div className="common-section-btn">
                          <Link to={el.linkUrl} className="common-btn">
                            {el.linkLabel}
                          </Link>
                          <Link to='/hair-health'>
                        Learn about Hair Loss</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })}

            {/* <div className="we-just-common-block rtl-block">
              <div className="we-just-col">
                <div className="we-just-image-block">
                  <GatsbyImage
                    image={getImage(image2)}
                    alt="We Just Img"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="we-just-col">
                <div className="we-just-content-block">
                  <div className="we-just-title">
                    <h3>{title2}</h3>
                  </div>
                  <div className="common-desc">
                    <p>{text2.text2}</p>
                  </div>
                  <div className="common-section-btn">
                    <Link to={buttonUrl2} className="common-btn">
                      {buttonLabel2}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="we-just-common-block gray-block">
              <div className="we-just-col">
                <div className="we-just-content-block">
                  <div className="we-just-title">
                    <h3>{title3}</h3>
                  </div>
                  <div className="common-desc">
                    <p>{text3.text3}</p>
                  </div>
                  <div className="common-section-btn">
                    <Link to={buttonUrl3} className="common-btn">
                      {buttonLabel3}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="we-just-col we-order">
                <div className="we-just-image-block">
                  <GatsbyImage
                    image={getImage(image3)}
                    alt="We Just Img"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
       */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeCanHelp
