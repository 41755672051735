import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const OurPromise = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulProductOurPromiseCards9 {
        title
        text
        promises {
          id
          title
          description {
            description
          }
        }
      }
    }
  `)
  const { title, text, promises } = data.contentfulProductOurPromiseCards9
  return (
    <div className="our_promise_section">
      <div className="container">
        <div className="our_promise_inner_section">
          <div className="our_promise_title">
            <span>{text}</span>
            <h2>{title}</h2>
          </div>

          <div className="our_promise_row">
            {promises?.map(promise => (
              <div className="our_promise_col" key={promise.id}>
                <div className="our_promise_details">
                  <h3>{promise.title}</h3>
                  <p>{promise.description.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div id="faq"></div>
    </div>
  )
}

export default OurPromise
