import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

const Banner = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomeHero1 {
        heading
        text
        linkLabel
        linkUrl
      }
    }
  `)
  const { text, heading, linkLabel, linkUrl } = data.contentfulHomeHero1
  return (
    <div className="home-banner-section">
      <div className="container">
        <div className="home-banner-content">
          <p className="home-banner-label">{text}</p>
          <div className="home-banner-title">
            <h1>{heading}</h1>
          </div>
          <div className="home-explore-btn">
            <Link to={`/${linkUrl}`}>
              <span>{linkLabel}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.06"
                height="9.369"
                viewBox="0 0 19.06 9.369"
              >
                <path
                  d="M13.356,13.241H.511A.511.511,0,0,0,0,13.751v2.382a.511.511,0,0,0,.511.511H13.356V18.6a1.021,1.021,0,0,0,1.743.722l3.661-3.661a1.021,1.021,0,0,0,0-1.444L15.1,10.559a1.021,1.021,0,0,0-1.743.722Z"
                  transform="translate(0 -10.258)"
                  fill="#fff"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
